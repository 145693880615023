<template>
  <b-card class="pb-2">
    <div class="d-flex justify-content-between flex-wrap my-1">
      <h4>{{ $t('settings.mainData.title') }}</h4>
      <div class="d-flex">
        <b-button
          v-if="mainType !== 'CoordinationCenter'"
          variant="primary"
          class="ml-1"
          @click="onSubmit()"
        >
          {{ $t('form.actions.save') }}
        </b-button>
      </div>
    </div>
    <b-row>
      <b-col>
        <label for="type" class="font-weight-bold">
          {{ $t('settings.mainData.type') }}
        </label>
        <b-form-input
          id="type"
          v-model="translatedType"
          name="type"
          readonly
        />
      </b-col>
      <b-col>
        <label for="subtype" class="font-weight-bold">
          {{ $t('settings.mainData.subtype') }}
        </label>
        <b-form-input
          v-if="mainType === 'CoordinationCenter' || mainType === 'Project'"
          id="type"
          readonly
        />
        <v-select
          v-else
          v-model="subtype"
          :clearable="false"
          :searchable="false"
          :options="types"
          :get-option-label="(a) => a.name[locale] || Object.values(a.name)[0]"
          :placeholder="$t('form.select-placeholder')"
        />
      </b-col>
    </b-row>
    <b-row v-if="noMain" class="mt-1">
      <b-col>
        <label for="main" class="font-weight-bold">
          {{ $t('settings.mainData.main-community') }}
        </label>
        <b-form-input
          id="main"
          v-model="mainData.mainCommunity"
          name="main"
          readonly
        />
      </b-col>
      <b-col>
        <label for="parent" class="font-weight-bold">
          {{ $t('settings.mainData.parent-community') }}
        </label>
        <b-form-input
          id="parent"
          v-model="mainData.parent"
          name="parent"
          readonly
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';
import { MainTypeTranslated } from '@copernicsw/community-common';

export default {
  name: 'MainData',
  components: { vSelect },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      subtype: null,
    };
  },
  computed: {
    mainData() {
      return this.$store.getters.mainData.unpaginated?.[0];
    },
    noMain() {
      return this.mainData?.mainCommunity || this.mainData?.parent;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    types() {
      //TODO: get subtypes depending on main type

      return this.$store.getters.subtypes.unpaginated;
    },
    currentCommunity() {
      return this.$store.getters.currentCollective;
    },
    mainType() {
      return this.mainData?.type;
    },
    translatedType() {
      const types = {
        Community: MainTypeTranslated[0],
        Event: MainTypeTranslated[1],
        Course: MainTypeTranslated[2],
        Project: MainTypeTranslated[3],
        CoordinationCenter: MainTypeTranslated[4],
        HelpCenter: MainTypeTranslated[5],
        Section: MainTypeTranslated[6],
        Subcommunity: MainTypeTranslated[7],
        Workspace: MainTypeTranslated[8],
        Organization: MainTypeTranslated[9],
        Challenge: MainTypeTranslated[10],
        Service: MainTypeTranslated[11],
      };
      try {
        return types[this.mainData?.type][this.locale];
      } catch {
        if (types[this.mainData?.type] != null) {
          return Object.values(types[this.mainData?.type])[0];
        }
        return null;
      }
    },
  },
  async created() {
    if(!this.mainData){
      await this.getMainData();
    }
    await this.getTypes();
    // console.log('mainData:', this.mainData);
    if (this.mainData?.subtype) {
      // console.log("checking subtype...");
      this.subtype = this.types?.find((item) => (item?.name[this.locale] || Object.values(item.name)[0]) === (this.mainData?.subtype[this.locale] || Object.values(this.mainData?.subtype)[0]));
    }
  },
  methods: {
    async getMainData() {
      await this.$store.dispatch('getItems', {
        itemType: 'communities/mainData',
        customName: 'mainData',
        page: 1,
        perPage: 15,
      });
    },
    async getTypes() {
      const requestConfig = {
        showID: true,
        modelType: this.mainType ? this.mainType.toLowerCase() : 'collective',
        getCommunityTypes: 1,
        selectAll: 1,
        showAllFromType: true,
      };
      //TODO: better add next to common:
      await this.$store.dispatch('getItems', {
        itemType: 'types',
        customName: 'subtypes',
        page: 1,
        perPage: 100,
        communitySlug: this.currentCommunity.parentCollective?.slug,
        requestConfig,
      });
    },
    async onSubmit() {
      try {
        await this.$store.dispatch('editCommunity', {
          settings: {
            type: this.subtype.id,
          },
        });
        this.notifySuccess(this.$t('backoffice.settings.messages.success'));
      } catch {
        this.notifyError(this.$t('backoffice.settings.messages.error'));
      }
    },
  },
};
</script>
